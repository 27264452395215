const RULES = [
  {
    expression: /(c|k)aff(e|è)/i,
    tags: ['coffe'],
  },
  {
    expression: /((mattina)[a-z]*)|((buongio)[a-z]+)|(buond(ì|i))|(buon giorno)/i,
    tags: ['morning'],
  },
  {
    expression: /notte|dormire|sogn(i|o)/i,
    tags: ['night forest', 'night sky'],
  },
  {
    expression: /(am(o|a)|amore|amare|cuore)/i,
    tags: ['love', 'heart'],
  },
  {
    expression: /domenica/i,
    tags: ['family'],
  },
  {
    expression: /sorridere|sorriso/i,
    tags: ['smile'],
  },
]

const DEFAULT_TAGS = ['nature', 'water', 'landscape']

export function getTagsFromPensiero(pensiero: string) {
  // Match first rule expression and return their tags
  for (let i = 0; i < RULES.length; i++) {
    const rule = RULES[i]
    if (pensiero.match(rule.expression)) {
      console.log('Tags',pensiero, rule.tags)
      return rule.tags.join(',')
    }
  }
  // Fallback 2 defaults tags
  // console.log('Use default tags', DEFAULT_TAGS)
  return DEFAULT_TAGS.join(',')
}