import React, { useCallback, useEffect, useRef, useState } from 'react'
import { saveAs } from 'file-saver'
import html2canvas from 'html2canvas'
import './App.css'
import BoomerImage from './BoomerImage'
import { getTagsFromPensiero } from './pensieri'
import pensieri from './data/pensieri.json'

export function randomIndex() {
  return Math.floor(Math.random() * pensieri.length)
}

const CAN_SHARE = typeof navigator.share === 'function'

const CAN_COPY_2_CLIPBOARD = typeof navigator.clipboard.writeText === 'function'

const MAX_PENDING_REQUESTS = 5

interface Slide {
  index: number
  url?: string
}

// New empty slide!
function generateSlide(): Slide {
  const index = randomIndex()
  const slide = {
    index,
  }
  return slide
}

const START_SLIDE = generateSlide()

function App() {
  const boxRef = useRef<HTMLDivElement>(null)

  const [slides, setSlides] = useState<Slide[]>([START_SLIDE])
  const [slideIndex, setSlideIndex] = useState<number>(0)

  const slide = slides[slideIndex]
  const imageUrl = slide.url
  const pensiero = pensieri[slide.index]

  const controllerRef = useRef<AbortController[]>([])
  const flightsRef = useRef<Record<string, boolean>>({})
  const generateImageSlideAt = useCallback((slide: Slide, index: number) => {
    // Image in flight
    if (flightsRef.current[index]) {
      return
    }
    // Grab pensiero
    const pensiero = pensieri[slide.index]
    // Calculate related tags
    const tags = getTagsFromPensiero(pensiero)

    // Abort prev fetch if in flight kepp N in flight
    const controller = new AbortController()
    if (controllerRef.current.length > MAX_PENDING_REQUESTS) {
      const killController = controllerRef.current.shift()
      if (killController) {
        killController.abort()
      }
    }
    controllerRef.current.push(controller)

    flightsRef.current[index] = true

    const signal = controller.signal

    const cleanUp = () => {
      const rmIndex = controllerRef.current.indexOf(controller)
      controllerRef.current.splice(rmIndex)
      delete flightsRef.current[index]
    }

    // Generate a random static culr for the image
    const sig = Math.random()
    const imageUrl = `https://source.unsplash.com/900x1200/?${tags}&sig=${sig}`
    fetch(imageUrl, { signal }).then(
      (response) => {
        cleanUp()
        setSlides((slides) =>
          slides.map((s, i) => (i === index ? { ...s, url: response.url } : s))
        )
      },
      (err) => {
        cleanUp()
        console.error("Can't download image", err)
        // Retry request if not aborted by the user ...
        if (err.name !== 'AbortError') {
          generateImageSlideAt(slide, index)
        }
      }
    )
  }, [])

  const goNext = useCallback(() => {
    if (slideIndex + 1 >= slides.length) {
      // Create a new slide!
      const nextSlideIndex = slides.length
      const slide = generateSlide()
      // Update UI \w next text
      setSlides(slides.concat(slide))
      setSlideIndex(nextSlideIndex)
      // Async generate related image ...
      generateImageSlideAt(slide, nextSlideIndex)
    } else {
      // Slide alredy in memory!
      const nextSlideIndex = slideIndex + 1
      // Update UI
      setSlideIndex(nextSlideIndex)
      // Async generate related image when needed
      if (!slides[nextSlideIndex].url) {
        generateImageSlideAt(slide, nextSlideIndex)
      }
    }
  }, [generateImageSlideAt, slide, slideIndex, slides])

  const goPrev = useCallback(() => {
    if (slideIndex > 0) {
      const nextSlideIndex = slideIndex - 1
      // Update UI
      setSlideIndex(nextSlideIndex)
      // Async generate related image when needed
      if (!slides[nextSlideIndex].url) {
        generateImageSlideAt(slide, nextSlideIndex)
      }
    }
  }, [generateImageSlideAt, slide, slideIndex, slides])

  // Generate first slide!
  useEffect(() => {
    generateImageSlideAt(START_SLIDE, 0)
  }, [generateImageSlideAt])

  const createImageBlob = useCallback((): Promise<Blob> => {
    if (!boxRef.current) {
      return Promise.reject('Box not loaded')
    }
    return html2canvas(boxRef.current, {
      allowTaint: true,
      useCORS: true,
    }).then((canvas) => {
      return new Promise((resolve, reject) => {
        canvas.toBlob((blob) => {
          if (blob) {
            return resolve(blob)
          }
          return reject("Can't create blob")
        })
      })
    })
  }, [])

  const saveImage = useCallback(() => {
    createImageBlob().then(
      (blob) => {
        saveAs(blob, 'pensiero.png')
      },
      (err) => console.log(err)
    )
  }, [createImageBlob])

  const shareImage = useCallback(() => {
    createImageBlob().then(
      (blob) => {
        const file = new File([blob], 'boomer.png', {
          type: blob.type,
        })
        const shareData = {
          files: [file],
        } as ShareData
        navigator.share(shareData)
      },
      (err) => console.log(err)
    )
  }, [createImageBlob])

  const copyPensiero = useCallback(() => {
    navigator.clipboard.writeText(pensiero).then(
      () => console.log('Pensiero copied!'),
      (err) => console.log('An error while trying to copy to clipobard', err)
    )
  }, [pensiero])

  return (
    <div className="App">
      <div className="PensieriHeader">
        <div className="HeaderTitle">
          <h1>
            <span className="title">Pensieri</span> ...
          </h1>
          <span className="boomer-emoji">
            <span>{' 💗 '}</span>
          </span>
        </div>
        <div className="HeaderActions">
          {CAN_SHARE && (
            <span onClick={shareImage} className="emoji-head-action">
              {' 📤 '}
            </span>
          )}
          <span onClick={saveImage} className="emoji-head-action">
            {' 📷 '}
          </span>
          {CAN_COPY_2_CLIPBOARD && (
            <span onClick={copyPensiero} className="emoji-head-action">
              {' 📄  '}
            </span>
          )}
        </div>
      </div>
      <div className="Pensieri">
        <div ref={boxRef} className="PensieroBox">
          <BoomerImage
            src={imageUrl}
            alt={`Random b00mer stuff 4 ${pensiero}`}
          />
          <p>{pensiero}</p>
          <div onClick={goPrev} className="PrevArea"></div>
          <div onClick={goNext} className="NextArea"></div>
        </div>
      </div>
    </div>
  )
}

export default App
