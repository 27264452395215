import React from 'react'
import defaultBoomerSrc from './boomer-default.jpg'

export default function BoomerImage({
  src,
  alt,
}: {
  src: string | undefined
  alt: string
}) {
  return (
    <img
      crossOrigin="anonymous"
      className={`boomer-image ${src ? '' : 'loading'}`}
      src={src ?? defaultBoomerSrc}
      alt={alt}
    />
  )
}
